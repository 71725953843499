import React, { useState } from 'react';
import { MailIcon, LocationMarkerIcon, OfficeBuildingIcon } from '@heroicons/react/outline'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import axios from 'axios';
import { toast } from 'react-toastify';
import { countryList } from '../lib/country_list';

const countriesDropDown = countryList.countries.sort((a, b) => (a.name > b.name) ? 1 : -1)

const notifySuccess = () => toast.success('Thanks for contacting us, we will get back to you shortly.', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
});

const notifyFailed = () => toast.error('Something went wrong', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
});

const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    subject: '',
    message: '',
    country_code: "91"

};

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const onSubmit = (values, { resetForm }) => {

    values.phone_number = values.country_code + values.phone_number
    const json = JSON.stringify(values);

    axios.post(process.env.GATSBY_REST_API_URL + `/contact-uses`, json,
        {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then(res => {
            notifySuccess();

        })
        .catch(error => {
            notifyFailed();
            console.log(error.response.data.error)
        })
        resetForm()

};

const validationSchema = Yup.object({
    first_name: Yup.string().required('Required!'),
    last_name: Yup.string().required('Required!'),
    email: Yup.string().required('Required!').email('Invalid mail id').max(200, 'Email too long'),
    message: Yup.string().required('Required!').max(500, 'Message too long'),
    subject: Yup.string().required('Required!').max(200, 'Subject too long'),
    phone_number: Yup.string().required('Required!').matches(phoneRegExp, 'Phone number is not valid').max(20, 'Phone number too long'),
    country_code: Yup.string().required('Required'),
})

export default function ContactUs({ data }) {

    return (
        <div className="relative bg-light-blue pb-6">

            <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-6 ">
                <div className=" col-span-2 bg-primary-blue mb-8 lg:rounded-2xl  text-white h-full flex flex-col justify-center items-center py-6">
                    <h2 className="text-3xl  font-serif   font-bold tracking-tight sm:text-6xl">Contact</h2>

                    <p className="mt-3 text-lg pt-5 leading-6 font-semibold">
                        Have a question for us?
                    </p>

                    <dl className="mt-3 text-base flex flex-col justify-content items-start">

                        <div className="mt-2">
                            <dd className="flex">
                                <MailIcon className="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                                <span className="ml-3">pitch@pitchright.ventures</span>
                            </dd>
                        </div>
                        <div className="mt-6 flex">
                            <OfficeBuildingIcon className="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                            <dd className="ml-2 ">
                                <p>710, Gateway Plaza, Hiranandani</p>
                            </dd>
                        </div>
                        <div className="ml-8">Gardens, Powai, Mumbai 400 076</div>
                        <div className="flex mt-4">
                            <LocationMarkerIcon className="flex-shrink-0 h-6 w-6" aria-hidden="true" />
                            <div className=" ml-2">India | USA | UK | Singapore | UAE</div>
                        </div>
                    </dl>
                    <div className="mt-12 text-center">Looking for work opportunities? </div>
                    <div> Explore
                        <a href="/careers" className="ml-1 font-bold text-lg underline  ">Careers</a>
                    </div>

                </div>
                <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                    <div className="  px-4  sm:px-6 lg:col-span-4 mt-10 md:mt-0 lg:px-8 xl:pl-12">
                        <div className="max-w-lg mx-auto lg:max-w-none">
                            <Form action="#" method="POST" className="grid grid-cols-1 gap-y-6">
                                <div className="grid xs:grid-cols-2 gap-4 ">
                                    <div className="col-span-1">
                                        <label htmlFor="first_name" className="">
                                            First Name <span className="text-red-700 text-xl  align-middle">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            name="first_name"
                                            id="first_name"
                                            autoComplete="first_name"
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            placeholder="First name"
                                        />
                                        <ErrorMessage name='first_name'>
                                            {(errMsg) => <div className="text-red-600  ">{errMsg}</div>}
                                        </ErrorMessage>
                                    </div>
                                    <div className="col-span-1">
                                        <label htmlFor="last_name" className="">
                                            Last Name <span className="text-red-700 text-xl  align-middle">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            name="last_name"
                                            id="last_name"
                                            autoComplete="last_name"
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            placeholder="Last name"
                                        />
                                        <ErrorMessage name='last_name'>
                                            {(errMsg) => <div className="text-red-600 ">{errMsg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className="grid xs:grid-cols-2 gap-4 ">
                                    <div className="col-span-1">
                                        <label htmlFor="email" className="">
                                            Email <span className="text-red-700 text-xl  align-middle">*</span>
                                        </label>
                                        <Field
                                            type="email"
                                            name="email"
                                            id="email"
                                            autoComplete="email"
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            placeholder="example@domainname.com"
                                        />
                                        <ErrorMessage name='email'>
                                            {(errMsg) => <div className="text-red-600 ">{errMsg}</div>}
                                        </ErrorMessage>
                                    </div>

                                </div>
                                <div className="grid grid-cols-6 gap-1">
                                    <div className="col-span-2 md:col-span-2 ">
                                        <label htmlFor="country_code" className="">
                                            Country  <span className="text-red-700 text-xl  align-middle">*</span>
                                        </label>

                                        <Field
                                            as="select"
                                            name="country_code"
                                            id="country_code"
                                            autoComplete="country_code"
                                            className="text-base block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        >

                                            {/* <option value="91" className="text-sm">+91 India</option>
                                                <option value="1" className="text-sm" >+1 USA</option>
                                                <option value="65" className="text-sm">+65 Singapore</option>
                                                <option value="44" className="text-sm">+44 Britain</option>
                                                <option value="971" className="text-sm">+971 UAE</option> */}
                                            {

                                            }
                                            {countriesDropDown.map((option) => (
                                                <option value={option.code} className="text-sm">{option.name}</option>
                                            ))}
                                        </Field>
                                        <ErrorMessage name='country_code'>
                                            {(errMsg) => <div className="text-red-600 ">{errMsg}</div>}
                                        </ErrorMessage>
                                    </div>

                                    <div className="col-span-4 md:col-span-4 ">
                                        <label htmlFor="phone_number" className="">
                                            Mobile Number  <span className="text-red-700 text-xl  align-middle">*</span>
                                        </label>
                                        <Field
                                            type="text"
                                            name="phone_number"
                                            id="phone_number"
                                            autoComplete="phone_number"
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                            placeholder="Mobile Number"
                                        />
                                        <ErrorMessage name='phone_number'>
                                            {(errMsg) => <div className="text-red-600 ">{errMsg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>

                                <div className="col-span-1">
                                    <label htmlFor="subject" className="">
                                        Subject  <span className="text-red-700 text-xl  align-middle">*</span>
                                    </label>
                                    <Field
                                        type="text"
                                        name="subject"
                                        id="subject"
                                        autoComplete="subject"
                                        className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                                        placeholder="Subject"
                                    />
                                    <ErrorMessage name='subject'>
                                        {(errMsg) => <div className="text-red-600  ">{errMsg}</div>}
                                    </ErrorMessage>
                                </div>
                                <div>
                                    <div className="text-gray-400 flex justify-end font-raleway">
                                        Max 500 Characters
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="">
                                            Message <span className="text-red-700 text-xl  align-middle">*</span>

                                        </label>
                                        <Field as="textarea"
                                            id="message"
                                            name="message"
                                            rows={4}
                                            className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 border border-gray-300 rounded-md"
                                            placeholder="Message"
                                            defaultValue={''}
                                        />
                                        <ErrorMessage name='message'>
                                            {(errMsg) => <div className="text-red-600 ">{errMsg}</div>}
                                        </ErrorMessage>
                                    </div>
                                </div>
                                <div>
                                    <button
                                        type="submit"
                                        className="inline-flex justify-center py-3 px-12 border border-transparent shadow-sm text-base font-medium rounded-full text-white bg-orange2 hover:bg-orange-light1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    >
                                        Send
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </Formik>
            </div>
            {/* <SignupNewsletter /> */}
        </div>
    )
}
